jQuery(document).ready(function ($) {
  "use strict";

  // Platform Detection
  const IOS =
      /iPad|iPhone|iPod/.test(navigator.userAgent) &&
      !window.MSStream,
    Android =
      navigator.userAgent.toLowerCase().indexOf("android") >
      -1;

  // Browser Windows Sizes
  const browser = {
    w: document.documentElement.clientWidth,
    h: document.documentElement.clientHeight,
  };

  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty(
    "--vh",
    `${vh}px`
  );

  $(window).on("resize orientationchange", () => {
    browser.w = document.documentElement.clientWidth;
    browser.h = document.documentElement.clientHeight;

    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty(
      "--vh",
      `${vh}px`
    );
  });

  /* ----------------------------------- Scrollbar CSS Variable ------------------------------------ */
  {
    const getScrollbarWidth = () => {
      let outer = document.createElement("div");
      outer.style.visibility = "hidden";
      outer.style.width = "100px";
      outer.style.msOverflowStyle = "scrollbar"; // needed for WinJS apps

      document.body.appendChild(outer);

      let widthNoScroll = outer.offsetWidth;
      // force scrollbars
      outer.style.overflow = "scroll";

      // add innerdiv
      let inner = document.createElement("div");
      inner.style.width = "100%";
      outer.appendChild(inner);

      let widthWithScroll = inner.offsetWidth;

      // remove divs
      outer.parentNode.removeChild(outer);

      return widthNoScroll - widthWithScroll;
    };

    if (
      $("body").outerHeight() <=
      document.documentElement.clientHeight
    ) {
      document.documentElement.style.setProperty(
        "--scroll-width",
        0
      );
    } else {
      if (
        $("html").hasClass("cssscrollbar") &&
        !(
          Android ||
          IOS ||
          document.documentElement.clientWidth <= 991
        )
      ) {
        document.documentElement.style.setProperty(
          "--scroll-width",
          getComputedStyle(
            document.documentElement
          ).getPropertyValue("--scrollbar-width")
        );
      } else {
        document.documentElement.style.setProperty(
          "--scroll-width",
          getScrollbarWidth() + "px"
        );
      }
    }
  }

  /* ----------------------------------- Anchor Scroll ------------------------------------ */
  {
    $('a[href^="#"]:not(.case-study-popup)').on(
      "click",
      function (e) {
        e.preventDefault();

        const href = $(this).attr("href");

        if (!(href === "#")) {
          const section = $(href);

          if (section.length) {
            const offset = $(section).offset().top;
            $("html,body").animate(
              {
                scrollTop: offset,
              },
              600
            );
          }
        } else {
          return false;
        }

        $.magnificPopup.close();
      }
    );
  }

  /* ----------------------------------- Side Menu ------------------------------------ */
  {
    // Open
    $(".nav-main .nav-toggle").on("click", function (e) {
      e.preventDefault();
      $(".nav-side").addClass("active");
      scrollLock.disablePageScroll();
    });

    // Close
    $(".nav-side .nav-close").on("click", function (e) {
      e.preventDefault();
      $(".nav-side").removeClass("active");
      scrollLock.enablePageScroll();
    });

    // Close on click outside
    $(document).on("click", function (e) {
      if ($(".nav-side.active").length) {
        if (
          $(e.target).parents(".nav-side").length ||
          $(e.target).parents(".nav-toggle").length ||
          $(e.target).hasClass("nav-toggle")
        ) {
        } else {
          $(".nav-side").removeClass("active");
          scrollLock.enablePageScroll();
        }
      }
    });

    // Sub Menu
    $(".nav-side .menu>li>a .icon").on(
      "click",
      function (e) {
        e.preventDefault();
        $(this)
          .parent()
          .siblings(".sub-menu")
          .slideToggle("fast");
        $(this).toggleClass("active");
      }
    );
  }

  /* ----------------------------------- About Slider ------------------------------------ */
  {
    if (document.querySelector(".slider-about")) {
      let args = {
        direction: "horizontal",
        loop: false,
        slidesPerView: "auto",
        slidesOffsetBefore:
          document.querySelector(".hero-section__container")
            .offsetLeft + 15,
        slidesOffsetAfter:
          document.querySelector(".hero-section__container")
            .offsetLeft + 15,
        grabCursor: true,
      };

      if (browser.w <= 991) {
        args.slidesOffsetBefore =
          document.querySelector(".hero-section__container")
            .offsetLeft +
          15 +
          50;
        args.slidesOffsetAfter =
          document.querySelector(".hero-section__container")
            .offsetLeft +
          15 +
          50;
      }

      const parterSwiperSlider = new Swiper(
        ".slider-about",
        args
      );
    }
  }

  /* ----------------------------------- Services Slider ------------------------------------ */
  {
    if (document.querySelector(".slider-services")) {
      $(".slider-services").each(function () {
        const slider = $(this);

        let args = {
          direction: "horizontal",
          loop: false,
          slidesPerView: "auto",
          slidesOffsetBefore:
            document.querySelector(
              ".services-section__container"
            ).offsetLeft + 15,
          slidesOffsetAfter:
            document.querySelector(
              ".services-section__container"
            ).offsetLeft + 15,
          grabCursor: true,
          navigation: {
            nextEl: slider
              .parents(".section")
              .eq(0)
              .find(".next")[0],
            prevEl: slider
              .parents(".section")
              .eq(0)
              .find(".prev")[0],
          },
        };

        const servicesSwiperSlider = new Swiper(
          slider[0],
          args
        );
      });
    }
  }

  /* ----------------------------------- Progress Slider ------------------------------------ */
  {
    if (document.querySelector(".slider-benefits")) {
      $(".slider-benefits").each(function () {
        const slider = $(this);

        let args = {
          direction: "horizontal",
          loop: false,
          slidesPerView: "auto",
          slidesOffsetBefore:
            document.querySelector(
              ".benefits-slider-wrap .container"
            ).offsetLeft + 15,
          slidesOffsetAfter: document.querySelector(
            ".benefits-slider-wrap .container"
          ).offsetLeft,
          grabCursor: true,
          navigation: {
            nextEl: slider
              .parents(".benefits-slider-wrap")
              .eq(0)
              .find(".next")[0],
            prevEl: slider
              .parents(".benefits-slider-wrap")
              .eq(0)
              .find(".prev")[0],
          },
        };

        const benefitsSwiperSlider = new Swiper(
          slider[0],
          args
        );
      });
    }
  }

  /* ----------------------------------- Benefits Slider ------------------------------------ */
  {
    if (document.querySelector(".slider-progress")) {
      $(".slider-progress").each(function () {
        const slider = $(this);

        let args = {
          direction: "horizontal",
          loop: false,
          slidesPerView: "auto",
          slidesOffsetBefore:
            document.querySelector(
              ".progress-slider-wrap .container"
            ).offsetLeft + 15,
          grabCursor: true,
          navigation: {
            nextEl: slider
              .parents(".progress-slider-wrap")
              .eq(0)
              .find(".next")[0],
            prevEl: slider
              .parents(".progress-slider-wrap")
              .eq(0)
              .find(".prev")[0],
          },
        };

        const progressSliderSwiper = new Swiper(
          slider[0],
          args
        );
      });
    }
  }

  /* ----------------------------------- Team About Slider ------------------------------------ */
  {
    if (document.querySelector(".team-slider-about")) {
      let args = {
        direction: "horizontal",
        loop: true,
        slidesPerView: "auto",
        grabCursor: true,
        pagination: {
          el: ".about-slider-dots",
          type: "bullets",
          clickable: true,
        },
      };

      const teamSliderAbout = new Swiper(
        ".team-slider-about",
        args
      );
    }
  }

  /* ----------------------------------- References Slider ------------------------------------ */
  {
    if (document.querySelector(".refs-slider")) {
      $(".refs-slider").each(function () {
        const slider = $(this);

        let args = {
          initialSlide: 0,
          speed: 400,
          direction: "horizontal",
          slidesPerView: "auto",
          grabCursor: true,
          effect: "fade",
          loop: false,
          fadeEffect: {
            crossFade: true,
          },
          navigation: {
            nextEl: slider.find(".next")[0],
            prevEl: slider.find(".prev")[0],
          },
        };
        const refsSliderSwiper = new Swiper(
          slider[0],
          args
        );
      });
    }
  }

  /* ----------------------------------- Phone Mask ------------------------------------ */
  {
    if ($(".phone-mask").length) {
      $(".phone-mask").inputmask("(+4\\9) 999-999-999-99", {
        placeholder: "_",
      });
    }
  }

  /* ----------------------------------- Cookies ------------------------------------ */
  {
    function setCookie(name, value, days) {
      var expires = "";
      if (days) {
        var date = new Date();
        date.setTime(
          date.getTime() + days * 24 * 60 * 60 * 1000
        );
        expires = "; expires=" + date.toUTCString();
      }
      document.cookie =
        name + "=" + (value || "") + expires + "; path=/";
    }

    function getCookie(name) {
      var nameEQ = name + "=";
      var ca = document.cookie.split(";");
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ")
          c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0)
          return c.substring(nameEQ.length, c.length);
      }
      return null;
    }

    $(".cookie-banner .btn-accept-cookie").on(
      "click",
      function (e) {
        e.preventDefault();
        setCookie("coc-01-cookie-accept", "true", 14);
        $(".cookie-banner").removeClass("active");
      }
    );

    window.onload = function () {
      setTimeout(() => {
        if (getCookie("coc-01-cookie-accept") == "true") {
          $(".cookie-banner").remove();
        } else {
          $(".cookie-banner").addClass("active");
        }
      }, 500);
    };
  }

  /* ----------------------------------- Case Study Popup ------------------------------------ */
  {
    if ($(".case-study-popup").length) {
      $(".case-study-popup").magnificPopup({
        type: "inline",
        preloader: false,
        modal: false,
        showCloseBtn: false,
      });

      $(document).on(
        "click",
        ".popup-modal-dismiss, .close-modal",
        function (e) {
          e.preventDefault();
          $.magnificPopup.close();
        }
      );

      $(".modal-scroller").on("click", function (e) {
        e.preventDefault();
        if ($(e.target).hasClass("modal-scroller")) {
          $.magnificPopup.close();
        }
      });
    }
  }

  /* ----------------------------------- Accordions ------------------------------------ */
  {
    $(".accordion-wrapper").each(function () {
      const accItems = $(this).find(".acc-item");

      $(this)
        .find(".acc-item.active")
        .find(".acc-item__body")
        .slideDown(0);

      accItems.each(function () {
        let delay = true;
        $(this).on("click", function (e) {
          if (delay) {
            delay = false;

            $(this)
              .siblings(".acc-item")
              .removeClass("active");
            $(this)
              .siblings(".acc-item")
              .find(".acc-item__body")
              .slideUp("fast");

            $(this).toggleClass("active");
            $(this)
              .find(".acc-item__body")
              .slideToggle("fast");
            setTimeout(() => {
              delay = true;
            }, 100);
          }
        });
      });
    });
  }

  /* ----------------------------------- Footer Form ------------------------------------ */
  {
    $(".form-handler").on("click", function (e) {
      e.preventDefault();
      $(this).remove();
      $(".form-wrap").addClass("active");
      location.hash = "#open-form";
    });

    if (location.hash == "#open-form") {
      $(".form-handler").remove();
      $(".form-wrap").addClass("active");
    }

    $(".form-footer").on("submit", function (e) {
      e.preventDefault();

      const form = $(this);
      let data = new FormData();

      // Ajax Settings
      data.append(
        "name",
        form.find('input[name="form-name"]').val()
      );
      data.append(
        "email",
        form.find('input[name="form-email"]').val()
      );
      data.append(
        "firma_name",
        form.find('input[name="form-name-firma"]').val()
      );
      data.append(
        "phone",
        form.find('input[name="form-tel"]').val()
      );
      data.append(
        "message",
        form.find('textarea[name="form-message"]').val()
      );

      $.ajax({
        url: "mail.php",
        data: data,
        type: "POST",
        cache: false,
        dataType: "json",
        processData: false,
        contentType: false,
        beforeSend: function (xhr) {
          form
            .find("button[type=submit]")
            .attr("disabled", true);
          $("body").addClass("wait");
        },
        complete: function (response) {
          console.log(response);
          if (
            response.responseJSON.admin_email == "success"
          ) {
            Swal.fire({
              icon: "success",
              title: "Erfolgreich",
              text:
                "Vielen Dank, Ihre Anfrage wurde gesendet.",
              confirmButtonText: "schließen",
            });

            form.trigger("reset");
          } else {
            Swal.fire({
              icon: "error",
              title: "Fehler",
              text:
                "Etwas ist schief gelaufen, bitte versuchen Sie es erneut",
              confirmButtonText: "OK",
            });
          }
          setTimeout(() => {
            form
              .find("button[type=submit]")
              .attr("disabled", false);
            $("body").removeClass("wait");
          }, 150);
        },
      });
    });
  }
});
